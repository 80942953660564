import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import axios from 'axios'
// for forms
import { StyledForm, HomeButtonRow, HomeButton } from '../../../../sea-site-theme/src/components/form/styles'
import { Formik } from 'formik'
import { InputItems, initialValues, validationSchema } from '../../../../sea-site-theme/src/components/form/utils'
import FormFieldGroup from '../../../../sea-site-theme/src/components/form/FormFieldGroup'
import FormFieldSingle from '../../../../sea-site-theme/src/components/form/FormFieldSingle'
import { BLOCKS, Block, Inline, Text } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import {
	Section,
	Container,
	Item,
	FormItem,
	ItemBackgroundGradient,
	ItemDescription,
	MultipleItemContainer,
	MultipleItem,
	MultipleItemLink,
	MultipleItemSplit,
	FirstItemText,
	ItemText,
	LastItemText,
	Bold,
	FormTitle,
	FormDescription,
	PostLink,
	BookButton,
	BookLink,
	BannerContainer,
} from './styles'

type Posts = {
	node: {
		seoTitle: string
		seoKeywords: string[]
		seoDescription: string
		type: string
		slug: string
		image: {
			fluid: {
				src: string
				srcWebp: string
			}
			description: string
		}
		node_locale: string
	}
}

type Subscribe = {
	node: {
		title: string
		description: string | undefined
		endpoint: string
		items: InputItems
		node_locale: string
		image: {
			fluid: {
				src: string
				srcWebp: string
			}
		}
	}
}

type Book = {
	node: {
		backgroundImage: {
			fluid: {
				src: string
				srcWebp: string
			}
		}
		description: {
			raw: string
		}
		node_locale: string
		mainActionText: string
		mainActionUrl: string
	}
}

interface ComponentProps {
	posts: {
		edges: Posts[]
	}
	subscribe: {
		edges: Subscribe[]
	}
	book: {
		edges: Book[]
	}
}

const News = (): JSX.Element => {
	const { posts, subscribe, book } = useStaticQuery<ComponentProps>(graphql`
		query getFeaturedNews {
			posts: allContentfulPost(filter: { featured: { in: true } }, sort: { fields: dateAndTime, order: DESC }) {
				edges {
					node {
						seoTitle
						seoKeywords
						seoDescription
						type
						slug
						image {
							fluid(quality: 60, maxWidth: 700, maxHeight: 700, cropFocus: CENTER, resizingBehavior: FILL) {
								src
								srcWebp
							}
							description
						}
						node_locale
					}
				}
			}
			book: allContentfulBanner(filter: { contentful_id: { eq: "3vQmnL8AAtAPdvuYw1Lgfq" } }) {
				edges {
					node {
						node_locale
						mainActionText
						mainActionUrl
						description {
							raw
						}
						backgroundImage {
							fluid(quality: 60, maxWidth: 1200, maxHeight: 650, cropFocus: CENTER, resizingBehavior: FILL) {
								src
								srcWebp
							}
						}
					}
				}
			}
			subscribe: allContentfulForm(filter: { contentful_id: { eq: "6taIb1VxqZEulSDN3ZmF4Z" } }) {
				edges {
					node {
						title
						description
						endpoint
						node_locale
						image {
							fluid(quality: 60, maxWidth: 400, maxHeight: 700, cropFocus: CENTER, resizingBehavior: FILL) {
								src
								srcWebp
							}
						}
						items {
							... on ContentfulFormField {
								helpText
								inputAutocomplete
								inputId
								inputName
								inputPlaceholder
								inputRequired
								inputType
								textareaCols
								textareaRows
								icon
								validationText
								width
								options
								node_locale
								label
							}
							... on ContentfulFormGroup {
								title
								items {
									width
									validationText
									textareaRows
									textareaCols
									options
									node_locale
									label
									inputType
									inputRequired
									inputPlaceholder
									inputName
									inputId
									inputAutocomplete
									icon
									helpText
								}
							}
						}
					}
				}
			}
		}
	`)
	/** Filter by locale */
	const post = posts.edges
	const bookDemo = book.edges
	const [{ node: subscription }] = subscribe.edges

	const option = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: function Paragraph(
				node: Block | Inline | Text,
				children: React.ReactChild | undefined | null | React.ReactFragment
			) {
				return (
					<ItemText background="#589bb2" padding="0.5rem 0.75rem">
						{children}
					</ItemText>
				)
			},
		},
	}

	return (
		<Section>
			<Container>
				<PostLink to={`/which-model`}>
					<Item bg={post[0].node.image}>
						<FirstItemText color="#fff">
							<Bold>{post[0].node.seoTitle}</Bold>
							<ItemDescription>{post[0].node.seoDescription}</ItemDescription>
						</FirstItemText>
					</Item>
				</PostLink>
				<MultipleItemContainer>
					<BannerContainer>
						<MultipleItemSplit bg={bookDemo[0].node.backgroundImage}></MultipleItemSplit>
						{documentToReactComponents(JSON.parse(bookDemo[0].node.description.raw), option)}
						<BookButton background="#589bb2" padding="0.25rem 0.75rem 1rem 0.75rem">
							<BookLink to={`/contact-us?subject=demo`}>{bookDemo[0].node.mainActionText}</BookLink>
						</BookButton>
					</BannerContainer>
					<MultipleItemLink to={`https://maytronics.com.au/post/${post[1].node.slug}`}>
						<MultipleItem bg={post[1].node.image} marginBottom="0">
							<LastItemText fontSize="1.25rem" color="#fff">
								{post[1].node.seoTitle}
							</LastItemText>
						</MultipleItem>
					</MultipleItemLink>
				</MultipleItemContainer>
				<FormItem bg={subscription.image} padding="1.5rem">
					<ItemBackgroundGradient />
					<FormTitle>{subscription.title}</FormTitle>
					<FormDescription>{subscription.description}</FormDescription>
					<Formik
						initialValues={initialValues(subscription.items)}
						validationSchema={validationSchema(subscription.items)}
						onSubmit={async (values, { setSubmitting, resetForm }) => {
							try {
								setSubmitting(true)
								const result = await axios.post('/.netlify/functions/mail', {
									formName: 'subscribe',
									subject: 'subscribe',
									...values,
								})
								console.log(result)
								setSubmitting(false)
								resetForm()
							} catch (error) {
								setSubmitting(false)
								return console.warn(error)
							}
						}}
					>
						{({ isSubmitting, errors, touched, values }) => {
							return (
								<StyledForm>
									{subscription.items.map((item, index: number) => {
										if (item.items) {
											const { title, items } = item
											return (
												<FormFieldGroup
													key={(title as string) + index}
													color="#fff"
													{...{ items, errors, touched, values }}
												/>
											)
										} else {
											return (
												<FormFieldSingle
													key={item.inputName + index}
													overrideWidth="full"
													color="#fff"
													{...{ item, errors, touched, values }}
												/>
											)
										}
									})}
									<HomeButtonRow>
										<HomeButton type="submit" disabled={isSubmitting}>
											Submit
										</HomeButton>
									</HomeButtonRow>
								</StyledForm>
							)
						}}
					</Formik>
				</FormItem>
			</Container>
		</Section>
	)
}

export default News
